<template>
  <div>
    <h4 class="mb-4">
      <strong>Edit Admin</strong>
    </h4>
    <div class="card">
      <div class="card-body">
        <a-form :form="form" layout="vertical" @submit.prevent="updateBalanceAdmin">
          <div class="row">
            <div class="col-md-6">
              <a-form-item label="Name">
                <a-input placeholder="Name" v-model.trim="company.name" />
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="RCSL Number">
                <a-input placeholder="RCSL Number" v-model.trim="company.rcslNumber"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Registration Number">
                <a-input placeholder="Registration Number" v-model.trim="company.registrationNumber"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Deposit Reference">
                <a-input placeholder="Deposit Reference" v-model.trim="reference" />
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Deposit Currency">
                <a-input placeholder="Deposit Currency" v-model.trim="currency"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Deposit Date">
                <a-date-picker style="width: 100%" v-model="depositDate" format="YYYY-MM-DD"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="Start Date">
                <a-date-picker style="width: 100%" v-model="startDate" format="YYYY-MM-DD"/>
              </a-form-item>
            </div>
            <div class="col-md-6">
              <a-form-item label="End Date">
                <a-date-picker style="width: 100%" v-model="endDate" format="YYYY-MM-DD"/>
              </a-form-item>
            </div>
            <div class="col-12">
              <a-form-item>
                <button type="submit" class="btn btn-light px-5" @click="() => notificationMethod('success','Administrative informations')">Update</button>
              </a-form-item>
            </div>
          </div>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as balanceService from '@/services/balance'

export default {
  name: 'EditBalanceAdmin',
  props: {
    id: String,
    notificationMethod: Function,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      reference: undefined,
      company: {},
      currency: undefined,
      year: undefined,
      depositDate: undefined,
      startDate: undefined,
      endDate: undefined,
    }
  },
  methods: {
    updateBalanceAdmin() {
      balanceService.updateBalanceAdmin(this, this.id)
    },
  },
  mounted() {
    balanceService.fetchBalanceAdmin(this, this.id)
  },
}
</script>

<style scoped>

</style>

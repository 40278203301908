<template>
  <div>
    <div class="kit__utils__heading">
      <h3>Edit {{ filename }}
        <a
          @click.prevent="download('pdf', $route.params.id, filename)"
          class="btn btn-sm btn-light"
        >
          Download PDF
          <i class="fe fe-download"></i>
        </a>
      </h3>
    </div>
    <div class="row">
      <div class="col-md-6" style="overflow: scroll; height: 2000px">
        <div>
          <pdf
            v-for="i in pdfNumPages"
            :key="i"
            :src="pdfSrc"
            :page="i"
            @error="error => console.error(error)"
          ></pdf>
        </div>
      </div>
      <div class="col-md-6">
        <a-tabs defaultActiveKey="1">
          <a-tab-pane tab="ADMIN" key="1">
            <edit-balance-admin :id="$route.params.id" :notificationMethod="notifyBalanceResourceUpdate" />
            <button class="btn btn-success px-5 mb-5 mr-1" @click.prevent="download('json', $route.params.id, JSONFilename)">Export</button>
            <button class="btn btn-danger px-5 mb-5" @click.prevent="deleteBalance">Delete</button>
          </a-tab-pane>
          <a-tab-pane v-if="types.includes('assets')" tab="ASSETS" key="2">
            <div id="assets" style="overflow: scroll; height: 2000px">
              <edit-balance-data :id="$route.params.id" type="assets" title="Edit Assets" language="EN" :notificationMethod="notifyBalanceResourceUpdate" />
              <button class="btn btn-success px-5 mb-5 mr-1" @click.prevent="download('json', $route.params.id, JSONFilename)">Export</button>
              <button class="btn btn-danger px-5 mb-5" @click.prevent="deleteBalance">Delete</button>
            </div>
          </a-tab-pane>
          <a-tab-pane v-if="types.includes('liabilities')" tab="LIABILITIES" key="3">
            <div id="liabilities" style="overflow: scroll; height: 2000px">
              <edit-balance-data :id="$route.params.id" type="liabilities" title="Edit Liabilities" language="EN" :notificationMethod="notifyBalanceResourceUpdate" />
              <button class="btn btn-success px-5 mb-5 mr-1" @click.prevent="download('json', $route.params.id, JSONFilename)">Export</button>
              <button class="btn btn-danger px-5 mb-5" @click.prevent="deleteBalance">Delete</button>
            </div>
          </a-tab-pane>
          <a-tab-pane v-if="types.includes('profit_and_loss')" tab="PROFIT AND LOSS" key="4">
            <div id="profit_and_loss" style="overflow: scroll; height: 2000px">
              <edit-balance-data :id="$route.params.id" type="profit_and_loss" title="Edit Profit & Loss" language="EN" :notificationMethod="notifyBalanceResourceUpdate" />
              <button class="btn btn-success px-5 mb-5 mr-1" @click.prevent="download('json', $route.params.id, JSONFilename)">Export</button>
              <button class="btn btn-danger px-5 mb-5" @click.prevent="deleteBalance">Delete</button>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import * as balanceService from '@/services/balance'
import apiClient from '@/services/api-client'
import pdf from 'vue-pdf'
import EditBalanceData from './data'
import EditBalanceAdmin from './admin'
import { Modal } from 'ant-design-vue'

export default {
  name: 'EditBalance',
  components: { EditBalanceAdmin, EditBalanceData, pdf },
  data() {
    return {
      filename: '',
      pdfSrc: pdf.createLoadingTask(apiClient.defaults.baseURL + '/api/balance/download/pdf/' + this.$route.params.id),
      pdfNumPages: 1,
      types: [],
    }
  },
  computed: {
    JSONFilename: function () {
      return this.filename.substring(0, this.filename.indexOf('.')) + '.json'
    },
  },
  methods: {
    notifyBalanceResourceUpdate(type, section) {
      this.$notification[type]({
        message: 'Update done',
        description: section + ' section has been updated correctly',
      })
    },
    download(mimeType, id, name) {
      balanceService.exportBalance(mimeType, id, name)
    },
    deleteBalance() {
      const $this = this
      Modal.confirm({
        title: 'Are you sure?',
        onOk() {
          balanceService.deleteBalance($this.$route.params.id, function () {
            $this.$router.push({ name: 'balances' })
          })
        },
        onCancel() {
          console.log('Cancel Deletion')
        },
        class: 'test',
      })
    },
  },
  mounted() {
    balanceService.fetchBalanceFilename(this, this.$route.params.id)
    balanceService.fetchBalanceTypes(this, this.$route.params.id)
    this.pdfSrc.promise.then(pdf => { this.pdfNumPages = pdf.numPages })
  },
}
</script>

<style scoped>

</style>

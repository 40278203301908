<template>
  <div class="card">
    <div class="card-body">
      <h4 class="mb-4">
        <strong>OCR-Quality Score Checker</strong>
      </h4>
      <h5 class="mb-4">
        <strong>Value Confidence and consistency rate</strong>
      </h5>
      <div class="mb-3">
        <a-alert v-if="rate == 100" :message="getQualityRateMessage('Top quality rate reached', rate)" type="success" showIcon />
        <a-alert v-else-if="rate >= 95" :message="getQualityRateMessage('Some corrections needed to reach top quality rate', rate)" type="warning" showIcon />
        <a-alert v-else :message="getQualityRateMessage('Low quality rate: massive corrections needed', rate)" type="error" showIcon />
      </div>
      <h5 class="mb-4" v-if="badKeys.length > 0">
        <strong>Fields to checkout</strong>
      </h5>
      <div class="mb-3" v-for="(value, index) in badKeys" :key="index">
        <a href="#" v-scroll-to="{ el: getAnchor(value), container: getAnchor() }">
          <a-alert :message="getFieldsToCheckout(value)" type="error" showIcon />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalanceQuality',
  props: {
    id: String,
    type: String,
    language: String,
    rate: Number,
    badKeys: Array,
    formatTitle: Function,
    source: Object,
  },
  methods: {
    getQualityRateMessage(label, rate) {
      return label + '. Current rate: ' + rate + '%'
    },
    getAnchor(key) {
      return '#' + this.type + (key !== undefined ? '_' + key.replace('.', '_') : '')
    },
    getFieldsToCheckout(pattern) {
      var keys = pattern.split('.')
      var source = this.source
      keys.forEach(function (key) {
        source = source[key]
      })
      return this.formatTitle(pattern) + ' ' + source.title[this.language]
    },
  },
}
</script>

<style scoped>

</style>

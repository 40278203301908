<template>
  <div>
    <h4 class="mb-4">
      <strong>{{ title }}</strong>
    </h4>
    <balance-quality :id="id" :type="type" :language="language" :rate="rate" :badKeys="badKeys" :formatTitle="formatTitle" :source="data"/>
    <div :id="getHtmlId(type, level_value)" class="card" v-for="(level_value, level_index) in levels" :key="level_index">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>{{ formatTitle(level_value) }} {{ data[level_value].title[language] }}</strong>
        </h5>
        <a-form :form="form" layout="vertical" @submit.prevent="updateBalanceData(id, type, level_value)">
          <div class="row">
            <ol class="row" style="width: 100%;">
              <div class="col-md-6 mb-3"><h5><strong>Current</strong></h5></div>
              <div class="col-md-6 mb-3"><h5><strong>Previous</strong></h5></div>
              <div class="col-md-6" v-for="(n, index) in 2" :key="index">
                <a-form-item :label="data[level_value].accounts[index].number">
                  <a-input :placeholder="data[level_value].accounts[index].number" v-model.trim="data[level_value].accounts[index].value" />
                </a-form-item>
              </div>
            </ol>
            <ol :id="getHtmlId(type, level_value, pattern)" class="row" style="width: 100%;" v-for="(pattern, pattern_index) in getPatterns(data[level_value])" :key="pattern_index">
              <div class="col-md-12">
                <h6 class="mb-3"><strong>{{ formatTitle(pattern) }} {{ getJSONNodeByPattern(data[level_value], pattern).title[language] }}</strong></h6>
              </div>
              <div class="col-md-6" v-for="(n, index) in 2" :key="index">
                <a-form-item :label="getJSONNodeByPattern(data[level_value], pattern).accounts[index].number">
                  <a-input :placeholder="getJSONNodeByPattern(data[level_value], pattern).accounts[index].number" v-model.trim="getJSONNodeByPattern(data[level_value], pattern).accounts[index].value" />
                </a-form-item>
              </div>
            </ol>
          </div>
          <a-form-item>
            <button type="submit" class="btn btn-light px-5" @click="() => notificationMethod('success',data[level_value].title[language])">Update</button>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import * as balanceService from '@/services/balance'
import BalanceQuality from './quality'

function getJSONKeys(source) {
  return Object.keys(source).filter(value => value.startsWith('line_'))
}

export default {
  name: 'EditBalanceData',
  components: { BalanceQuality },
  props: {
    id: String,
    type: String,
    title: String,
    language: String,
    notificationMethod: Function,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      data: {},
      rate: 100,
      badKeys: [],
    }
  },
  computed: {
    levels: function () {
      return getJSONKeys(this.data)
    },
  },
  mounted() {
    balanceService.fetchBalanceDataByType(this, this.id, this.type)
    balanceService.fetchBalanceQualityByType(this, this.id, this.type, false)
  },
  methods: {
    updateBalanceData(id, type, section) {
      balanceService.updateBalanceDataByType(this, id, type, section)
    },
    getHtmlId(type, level, key) {
      return type + '_' + level + (key !== undefined ? '_' + key.replace('.', '_') : '')
    },
    formatTitle(pattern) {
      const title = []
      pattern.split('.').forEach(function (element) {
        title.push(element.replace('line_', '') + '.')
      })
      return pattern !== 'line_TOTAL' ? title.join(' ') : ''
    },
    getJSONNodeByPattern(source, pattern) {
      pattern.split('.').forEach(function (element) {
        source = source[element]
      })
      return source
    },
    getPatterns(source) {
      const result = []
      getJSONKeys(source).forEach(function (lvl1) {
        if (source[lvl1] !== undefined) {
          result.push(lvl1)
          getJSONKeys(source[lvl1]).forEach(function (lvl2) {
            if (source[lvl1][lvl2] !== undefined) {
              result.push(lvl1 + '.' + lvl2)
              getJSONKeys(source[lvl1][lvl2]).forEach(function (lvl3) {
                if (source[lvl1][lvl2][lvl3] !== undefined) {
                  result.push(lvl1 + '.' + lvl2 + '.' + lvl3)
                  getJSONKeys(source[lvl1][lvl2][lvl3]).forEach(function (lvl4) {
                    if (source[lvl1][lvl2][lvl3][lvl4] !== undefined) {
                      result.push(lvl1 + '.' + lvl2 + '.' + lvl3 + '.' + lvl4)
                    }
                  })
                }
              })
            }
          })
        }
      })
      return result.sort()
    },
  },
}
</script>
